import { trackFacebookEvent } from "lib/facebook";
import { Controller } from "stimulus";

interface DataEvent {
  event: string;
}

declare global {
  interface Window {
    dataLayer?: DataEvent[];
  }
}

const dataLayer = (window.dataLayer = window.dataLayer ?? []);

export default class extends Controller {
  connect(): void {
    this.recordPage();
  }

  recordAction(event: MouseEvent): void {
    const data = JSON.parse(
      (event.target as HTMLElement).dataset.analytics ?? "{}"
    );
    dataLayer.push(data);
  }

  recordPage(): void {
    const event = {
      event: "Pageview",
      path: location.pathname + location.search,
      host: location.host,
    };
    dataLayer.push(event);
  }

  // Facebook Events
  sendFacebookLeadEvent(): void {
    trackFacebookEvent("Lead");
  }
  sendAddToCartEvent(): void {
    trackFacebookEvent("AddToCart");
  }
}
