import { Controller } from "stimulus";

export default class extends Controller {
  scrollToTop(): void {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
}
