let turbolinksScroll: number | undefined = undefined;

function trackScroll(): void {
  turbolinksScroll = document.scrollingElement?.scrollTop;
}

window.trackScroll = trackScroll;

document.addEventListener("turbolinks:load", () => {
  const elements = document.querySelectorAll("[data-turbolinks-scroll]");

  elements.forEach((element) => {
    element.addEventListener("click", trackScroll);
    element.addEventListener("submit", trackScroll);
  });

  if (turbolinksScroll) {
    document.scrollingElement?.scrollTo(0, turbolinksScroll);
  }

  turbolinksScroll = undefined;
});
