declare global {
  interface Window {
    fbq?: (action: "track", event: string) => void;
  }
}

export function trackFacebookEvent(
  event: "Lead" | "AddToCart" | "CompleteRegistration"
): void {
  window.fbq?.("track", event);
}
