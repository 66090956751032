import { Controller } from "stimulus";

export default class extends Controller {
  couponsTarget!: HTMLElement;
  couponsWrapperTarget!: HTMLElement;

  static targets = ["coupons", "couponsWrapper"];

  gap = 0;
  padding = 0;
  containerWidth = 0;
  resizeObserver: ResizeObserver | null = null;

  connect(): void {
    if (this.couponsTarget) {
      this.handleRange();
      this.resizeObserver = new ResizeObserver(() => {
        this.handleRange();
      });
      this.resizeObserver.observe(this.element);
    }
  }

  disconnect(): void {
    this.resizeObserver?.unobserve(this.element);
  }

  handleRange: () => void = () => {
    this.getGapAndPadding();
    this.containerWidth = this.element.getBoundingClientRect().width;

    this.resetParent();

    if (this.containerWidth < 300) {
      return this.setCouponsStyle(0, 182);
    }
    if (this.containerWidth >= 300 && this.containerWidth < 460) {
      return this.setCouponsStyle(2);
    }
    if (this.containerWidth >= 460 && this.containerWidth < 625) {
      return this.setCouponsStyle(3);
    }
    if (this.containerWidth >= 625 && this.containerWidth < 820) {
      return this.setCouponsStyle(4);
    }
    if (this.containerWidth >= 820 && this.containerWidth < 915) {
      return this.setCouponsStyle(5);
    }
    if (this.containerWidth >= 915) {
      this.removeParentScroll();
      return this.setCouponsStyle(6);
    }
  };

  getGapAndPadding(): void {
    if (this.couponsTarget.parentElement) {
      this.padding = getComputedStyle(this.couponsTarget.parentElement)[
        // eslint-disable-next-line
        // @ts-ignore
        "padding-left"
      ]?.split("px")[0];
    }

    this.gap = Number(
      getComputedStyle(this.couponsTarget)["gap"].split("px")[0]
    );
  }

  setCouponsStyle(columns = 0, size = 0): void {
    let elementSize = size;
    if (columns) {
      elementSize = this.getElementSize(this.containerWidth, columns);
    }
    this.couponsTarget.setAttribute(
      "style",
      `grid-template-columns: repeat(6, ${elementSize}px) 0px;`
    );
  }

  removeParentScroll(): void {
    if (this.couponsWrapperTarget) {
      this.couponsWrapperTarget.setAttribute("style", "overflow-x: unset");
    }
  }

  resetParent(): void {
    if (this.couponsWrapperTarget) {
      this.couponsWrapperTarget.removeAttribute("style");
    }
  }

  getElementSize(containerWidth: number, elements: number): number {
    const size =
      (containerWidth - this.padding * 2 - this.gap * (elements - 1)) /
      elements;

    return size;
  }
}
