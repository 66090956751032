import { Controller } from "stimulus";

export default class extends Controller {
  private itemTargets: HTMLDivElement[] | undefined;
  private selectTarget: HTMLSelectElement | undefined;

  static targets = ["item", "select"];

  connect(): void {
    if (this.itemTargets?.[0]) {
      this.itemTargets[0].classList.add("active");
      if (this.selectTarget) {
        this.selectTarget.value = this.itemTargets[0].dataset.id || "";
      }
    }
  }

  select(event: { target: { value: string } }): void {
    this.itemTargets?.forEach((item) => {
      if (item.dataset.id === event.target.value) {
        item.classList.add("active");
      } else {
        item.classList.remove("active");
      }
    });
  }
}
