import { Controller } from "stimulus";

export default class extends Controller {
  contentTarget!: HTMLElement;
  static targets = ["content"];

  toggle(): void {
    const open = this.element.classList.toggle("open");
    this.contentTarget.style.maxHeight = open
      ? `${this.contentTarget.scrollHeight}px`
      : "0px";
  }
}
