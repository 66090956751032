import { Controller } from "stimulus";

export default class extends Controller {
  private inputTarget: HTMLInputElement | undefined;

  static targets = ["input"];

  open(): void {
    this.element.classList.add("main-menu__search--active");
    this.inputTarget?.focus();
  }

  close(): void {
    this.element.classList.remove("main-menu__search--active");
  }
}
