import { Controller } from "stimulus";
import { copyToClipboard } from "lib/clipboard";
import { wait } from "lib/timing";

export default class extends Controller {
  originalText!: string;

  connect(): void {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.originalText = this.element.textContent!;
  }

  async shareOrCopy(event: Event): Promise<void> {
    event.preventDefault();
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const url = this.data.get("value")!;
    if (navigator.share) {
      await navigator.share({ url });
    } else {
      await copyToClipboard(url);
      const confirm = this.data.get("confirm");
      if (confirm) {
        this.element.textContent = confirm;
        this.element.classList.add("confirmed");
        await wait(3);
        this.element.textContent = this.originalText;
        this.element.classList.remove("confirmed");
      }
    }
  }
}
