import { Controller } from "stimulus";

export default class extends Controller {
  trackTarget!: HTMLElement;
  slideTarget!: HTMLElement;

  static targets = ["track", "slide"];

  slideWidth = 0;
  updateFunction: EventListener | null = null;

  connect(): void {
    this.updateSlideWidth();
    this.updateFunction = this.updateSlideWidth.bind(this);

    window.addEventListener("resize", this.updateFunction);
    this.trackTarget.scrollTo({
      left: 0,
      behavior: "instant",
    });
  }

  disconnect(): void {
    if (this.updateFunction === null) return;

    window.removeEventListener("resize", this.updateFunction);
  }

  updateSlideWidth(): void {
    this.slideWidth = this.slideTarget.offsetWidth;
    this.trackTarget.style.setProperty("--slide-width", `${this.slideWidth}px`);
  }

  previous(): void {
    const currentScrollPosition = this.trackTarget.scrollLeft;
    this.trackTarget.scrollTo({
      left: currentScrollPosition - this.slideWidth,
      behavior: "smooth",
    });
  }

  next(): void {
    const currentScrollPosition = this.trackTarget.scrollLeft;
    this.trackTarget.scrollTo({
      left: currentScrollPosition + this.slideWidth,
      behavior: "smooth",
    });
  }
}
