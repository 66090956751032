import { Controller } from "stimulus";

export default class extends Controller {
  contentTarget!: HTMLTemplateElement;
  static targets = ["content"];

  connect(): void {
    const shadow = this.element.attachShadow({ mode: "open" });
    shadow.innerHTML = this.contentTarget.innerHTML;
    const scriptTags = Array.from(shadow.querySelectorAll("script"));
    scriptTags.forEach((script) => {
      script.remove();
      const tag = document.createElement("script");
      if (script.src) tag.src = script.src;
      tag.innerHTML = script.innerHTML;
      shadow.appendChild(tag);
    });
    const event = new CustomEvent("external-element-loaded", {
      detail: shadow,
    });
    this.element.dispatchEvent(event);
  }
}
