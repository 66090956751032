import { Controller } from "stimulus";
import { wait } from "lib/timing";

export default class extends Controller {
  async openInNewTab(e: MouseEvent): Promise<void> {
    const isWeb = document.body.classList.contains("web");
    if (isWeb) {
      const targetUrl =
        (e.currentTarget as HTMLDivElement).dataset.trackingTab || "";
      // there is a race condition on Safari where the redirect doesn't work before the tab loses focus.
      await wait(0.05);
      window.location.href = targetUrl;
    } else {
      e.preventDefault();
      const target = e.currentTarget as HTMLAnchorElement;
      window.asyncModal(target.dataset.modalUrl ?? "", target);
    }
  }
}
