import { Controller } from "stimulus";
import { wait } from "lib/timing";

export default class extends Controller {
  copyButtonTarget!: HTMLAnchorElement;
  goToButtonTarget!: HTMLAnchorElement;
  static targets = ["copyButton", "goToButton"];
  async toggleCopied(): Promise<void> {
    this.copyButtonTarget.classList.add("button--outline");
    this.goToButtonTarget.classList.remove("button--outline");
    await wait(3);
    this.copyButtonTarget.classList.remove("button--outline");
    this.goToButtonTarget.classList.add("button--outline");
  }
}
