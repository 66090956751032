import Splide from "@splidejs/splide";
import { Controller } from "stimulus";

export default class extends Controller {
  splideTarget!: HTMLElement;
  splide!: Splide;

  static targets = ["splide"];

  connect(): void {
    const autoWidth = !!this.data.get("autoWidth"); // sets arrow instead of dots pagination

    this.splide = new Splide(this.splideTarget, {
      drag: true,
      arrows: autoWidth,
      wheel: false,
      perPage: 1,
      perMove: 1,
      gap: 32,
      mediaQuery: "min",
      paginationDirection: "ltr",
      pagination: !autoWidth,
      autoWidth: autoWidth,
      breakpoints: {
        ...(autoWidth
          ? {
              640: {
                gap: 64,
                perMove: 2,
              },
              1024: {
                gap: 48,
                perMove: 3,
              },
            }
          : {
              890: {
                perPage: 2,
                gap: 32,
              },
              1440: {
                perPage: 3,
                gap: 32,
              },
            }),
      },
    });
    if (!autoWidth) {
      this.splide.on("pagination:mounted", function (data) {
        data.list.classList.add("splide-slider__slider-dots-wrapper");
        data.items.forEach(function (item) {
          item.button.classList.add("splide-slider__slider-dot");
        });
      });
    }

    this.splide.mount();
  }

  disconnect(): void {
    this.splide.destroy();
  }
}
