import jQuery from "jquery";
import "slick-carousel";

import { Controller } from "stimulus";

export default class extends Controller {
  prevTarget!: HTMLElement;
  nextTarget!: HTMLElement;
  contentTarget!: HTMLElement;

  static targets = ["prev", "next", "content"];

  connect(): void {
    jQuery(this.contentTarget).slick({
      // prevArrow: this.prevTarget,
      // nextArrow: this.nextTarget,
      arrows: false,
      dots: true,
      appendDots: this.element,
      autoplay: false,
      autoplaySpeed: 5000,
      speed: 1000,
      responsive: [
        {
          breakpoint: 1327,
          settings: {
            centerPadding: "12px",
          },
        },
      ],
    });
  }
}
