require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");

require("intersection-observer");

import "sprinkles/patch";
import "sprinkles/turbolinks-scroll";
import "sprinkles/anchor-scroll";
import "controllers";
import "@nerdgeschoss/shimmer";

window.addEventListener("load", () => {
  if (
    document.body.classList.contains("native") &&
    "serviceWorker" in navigator
  ) {
    navigator.serviceWorker.register("/service-worker.js");
  }
});
