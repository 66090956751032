import { trackFacebookEvent } from "lib/facebook";
import { Controller } from "stimulus";

export default class extends Controller {
  reset(): void {
    const form = this.element as HTMLFormElement;

    if (form.checkValidity()) {
      trackFacebookEvent("CompleteRegistration");
      form.submit();
      form.reset();
    }
  }
}
