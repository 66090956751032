import jQuery from "jquery";
import { Controller } from "stimulus";

export default class extends Controller {
  element!: HTMLElement;
  observer!: IntersectionObserver;
  loaded = false;

  connect(): void {
    this.observer = new IntersectionObserver(this.entered.bind(this), {});
    this.observer.observe(this.element);
  }

  private entered(entries: IntersectionObserverEntry[]): void {
    if (entries[0].isIntersecting && !this.loaded) {
      this.load();
      this.observer.disconnect();
      this.loaded = true;
    }
  }

  private load(): void {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const path = this.data.get("path")!;
    jQuery(this.element).load(path, (data) => {
      if (!data) {
        this.element.remove();
      }
    });
  }

  disconnect(): void {
    this.observer.disconnect();
  }
}
