import { wait } from "lib/timing";

export const offset = -100;

async function offsetAnchor(): Promise<void> {
  await wait(0);
  if (window.location.hash.includes("coupon-")) {
    window.scrollTo(window.scrollX, window.scrollY + offset);
  }
}

document.addEventListener("DOMContentLoaded", offsetAnchor);
