import { Controller } from "stimulus";

export default class extends Controller {
  private contentTarget!: HTMLDivElement;
  static targets = ["content"];

  toggleExpanded(): void {
    if (this.element.classList.contains("collapse-panel--expanded")) {
      this.collapse();
    } else {
      this.expand();
    }
  }

  expand(): void {
    this.element.classList.add("collapse-panel--expanded");
  }

  collapse(): void {
    this.element.classList.remove("collapse-panel--expanded");
  }

  closePanel: (event: MouseEvent) => void = (event) => {
    if (event.target !== this.contentTarget) {
      this.collapse();
      document.removeEventListener("click", this.closePanel, {
        capture: true,
      });
    }
  };

  enableClickOutside(): void {
    document.addEventListener("click", this.closePanel, {
      capture: true,
    });
  }
}
