import jQuery from "jquery";
import { Controller } from "stimulus";
import { wait } from "lib/timing";
import Turbolinks from "turbolinks";

declare global {
  interface Window {
    asyncModal: ModalController["open"];
    closeModal: ModalController["close"];
  }
}

export default class ModalController extends Controller {
  modalWrapperTarget!: HTMLDivElement;
  modalContainerTarget!: HTMLDivElement;
  modalContentTarget!: HTMLDivElement;
  remoteContentTarget!: HTMLDivElement;
  source?: HTMLDivElement;
  currentSourceStartingPosition?: DOMRect;
  static targets = [
    "modalWrapper",
    "modalContainer",
    "modalContent",
    "remoteContent",
  ];

  connect(): void {
    Object.assign(window, {
      asyncModal: this.open.bind(this),
      closeModal: this.close.bind(this),
    });
  }

  async open(path: string, trigger?: HTMLElement): Promise<void> {
    await wait(0.01);
    const sourceId = window.location.hash.substring(1);
    this.source =
      trigger?.closest("[data-modal-source]") ??
      (document.getElementById(sourceId)
        ?.firstElementChild as HTMLDivElement) ??
      undefined;

    document.body.classList.add("no-scroll");
    this.currentSourceStartingPosition = this.source?.getBoundingClientRect();

    if (this.source && (sourceId || trigger)) {
      this.modalWrapperTarget.classList.add("modal--coupon-ready");
      if (this.isWeb) {
        this.fromSourceWithNoAnimation(path);
      } else {
        this.animateFromSource(path);
      }
      this.modalWrapperTarget.classList.remove("modal--coupon-ready");
    } else {
      this.animateFromBottom(path);
    }
  }

  async close(): Promise<void> {
    const homeUrl = this.modalWrapperTarget.dataset.homeUrl;

    /* We need the enableTransition flag to set the initial modal position
       without it animating to the position, specially useful when
       animating from a coupon. */
    this.source?.classList.add("coupon--enableTransition");
    this.source?.classList.remove("hidden");
    this.modalWrapperTarget.classList.remove("modal--open");

    // We need the timeout to give the modal time to animate out before cleaning up
    await wait(0.3);
    this.source?.classList.remove("coupon--enableTransition");
    document.body.classList.remove("no-scroll");
    this.modalContentTarget.classList.remove(
      "modal__content--enableTransition"
    );
    this.modalContentTarget.style.transform = "";
    this.modalContentTarget.style.transition = "";

    const isCouponModal = window.location.search.includes("coupon");

    if (homeUrl && isCouponModal && this.isWeb) {
      window.trackScroll();
      Turbolinks.visit(homeUrl);
    }
  }

  private get isWeb(): boolean {
    return document.body.classList.contains("web");
  }

  private async loadContent(path: string): Promise<void> {
    return new Promise((res, rej) => {
      jQuery.ajax({
        url: path,
        method: "GET",
        headers: {
          "X-Shimmer": "true",
        },
        success: (data) => {
          jQuery(this.remoteContentTarget).html(data);
          res();
        },
        error: (_jqXHR, textStatus, errorThrown) => {
          rej(`Failed to load content: ${textStatus}, ${errorThrown}`);
        },
      });
    });
  }

  private async animateFromBottom(path: string): Promise<void> {
    this.modalContentTarget.classList.add("modal__content--enableTransition");
    await this.loadContent(path);
    this.modalWrapperTarget.classList.add("modal--open");
  }

  private async animateFromSource(path: string): Promise<void> {
    this.modalContainerTarget.classList.add("modal--coupon-ready");
    // this code path won't be reached if there is no source
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const source = this.source!;

    await this.loadContent(path);

    // make sure the modal is aligned with the coupon before begining the animation
    await wait(0.1);

    this.modalContainerTarget.classList.remove("modal--coupon-ready");
    this.modalContentTarget.classList.add("modal__content--enableTransition");
    source.classList.add("coupon--enableTransition");
    // make sure classes are applied before proceeding
    await wait(0.01);

    this.modalWrapperTarget.classList.add("modal--open");
    this.modalContentTarget.style.transform = "";
  }

  private async fromSourceWithNoAnimation(path: string): Promise<void> {
    // this code path won't be reached if there is no source
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    // const source = this.source!;

    await this.loadContent(path);

    this.modalWrapperTarget.classList.add("modal--open");
  }
}
