import morphdom from "morphdom";

export function refreshData(): void {
  $.get(document.location.href, function (data: string) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    document.getElementById("main")!.innerHTML = data;
  });
}

export function serverPatchElement(selector: string, content: string): void {
  const element = document.querySelector(selector);
  if (element) {
    morphdom(element, content);
    document.dispatchEvent(new Event("turbolinks:load"));
  }
}

Object.assign(window, { refreshData, serverPatchElement });
