import { Controller } from "stimulus";
import { copyToClipboard } from "lib/clipboard";
import { wait } from "lib/timing";

export default class extends Controller {
  originalText!: string;

  connect(): void {
    this.originalText = this.element.textContent ?? "";
  }

  async copy(event: Event): Promise<void> {
    event.preventDefault();
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const value = this.data.get("value")!;
    await copyToClipboard(value);
    const confirm = this.data.get("confirm");
    if (confirm) {
      this.element.textContent = confirm;
      this.element.classList.add("confirmed");
      await wait(3);
      this.element.textContent = this.originalText;
      this.element.classList.remove("confirmed");
    }
  }
}
